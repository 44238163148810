// .MuiInputBase-input {
//   color: var(--text-dark);
// }

// .MuiListItemText-primary {
//   color: var(--text-dark);
// }

// .MuiListItemButton-root.Mui-selected {
//   background-color: var(--mui-selected-background-color);
//   &:hover {
//     background-color: var(--mui-selected-hover-background-color);
//   }
// }
// .MuiListItemButton-root {
//   &:hover {
//     background-color: var(--mui-hover-background-color);
//   }
// }

@mixin mui-styles() {
  .Mui-error {
    @apply text-error;
  }

  .Mui-disabled {
    @apply text-auto-black/60;
  }

  // .MuiButton-root {
  //   background-color: unset;
  //   background-image: unset;
  // }
}
