/**
animations.scss
*/

:root {
  --transition-design: cubic-bezier(0.14, 0.4, 0.09, 0.99) 0.5s;
  --transition: all cubic-bezier(0.14, 0.4, 0.09, 0.99) 2s;
}

.transition-cos {
  transition-timing-function: cubic-bezier(0.14, 0.4, 0.09, 0.99);
}

.transition-sin {
  transition-timing-function: cubic-bezier(0.29, 0.87, 0.77, 0.67);
}

.transition-spring {
  transition-timing-function: cubic-bezier(0.87, 1.54, 0.85, 0.89);
}

.duration-1500 {
  transition-duration: 1.5s;
}

.duration-2000 {
  transition-duration: 2s;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slide-in-from-left {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-in-from-right {
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-in-from-top {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-in-from-bottom {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes spring-zoom {
  0% {
    opacity: 0%;
    transform: scale3d(0.96, 0.96, 1);
  }
  20% {
    opacity: 20%;
    transform: scale3d(1.1, 1.1, 1);
  }
  40% {
    opacity: 40%;
    transform: scale3d(0.98, 0.98, 1);
  }
  60% {
    opacity: 60%;
    transform: scale3d(1.05, 1.05, 1);
  }
  80% {
    opacity: 80%;
    transform: scale3d(1.01, 1.01, 1);
  }
  100% {
    opacity: 100%;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes breathe {
  0%,
  30%,
  100% {
    transform: scale(1);
  }

  55% {
    transform: scale(1.05);
  }
}

.fade-in-from-left {
  animation-name: slide-in-from-left, fade-in;
  animation-duration: 1s, 1s;
  animation-timing-function: cubic-bezier(0.14, 0.4, 0.09, 0.99),
    cubic-bezier(0.14, 0.4, 0.09, 0.99);
  animation-delay: 0.5s;
}
