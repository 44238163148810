@mixin dark-mui() {
  .accordion-customization,
  .controlled-accordion {
    border-top: 1px solid var(--borderColor);
    border-left: 1px solid var(--borderColor);
    border-right: 1px solid var(--borderColor);

    .MuiAccordionDetails-root {
      border-top: 1px solid var(--borderColor);
    }

    .MuiButtonBase-root {
      .MuiAccordionSummary-expandIconWrapper {
        svg {
          color: #fff;
        }
      }
    }
  }

  .MuiDialog-root {
    .MuiDialog-container {
      .MuiPaper-root {
        background-color: #101010;
        color: var(--text-dark);
      }
    }
  }

  .Mui-disabled.MuiIconButton-root {
    color: #5d5e5e;
  }

  .MuiCard-root {
    background-color: var(--cardBg);
    border: 1px solid var(--borderColor);
  }

  .MuiPopover-paper {
    background-color: var(--bodyBg);
    &:before {
      background-color: var(--bodyBg);
    }
  }

  .MuiDialog-root {
    .MuiDialog-container {
      .MuiPaper-root {
        background-color: #101010;
      }
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #464545;
  }
  .with-icons-input {
    .MuiInput-underline {
      &::before {
        border-bottom: 1px solid #464545;
      }
    }
  }

  .MuiBadge-badge {
    color: #fff;
  }

  .MuiRating-iconEmpty {
    color: rgb(140 140 140 / 26%);
    svg {
      color: rgb(140 140 140 / 26%) !important;
    }
  }

  .MuiFormControl-root {
    .MuiFormLabel-root {
      color: var(--darkBodyTextColor);
    }
  }

  .MuiChip-root {
    .MuiChip-label {
      color: var(--darkBodyTextColor);
    }
  }

  .MuiListItemIcon-root {
    color: var(--darkHeadingTextColor);
  }
  .MuiDivider-root {
    border-color: rgb(255 255 255 / 12%);
  }

  .MuiDialogContent-dividers {
    border-top: 1px solid var(--borderColor);
    border-bottom: 1px solid var(--borderColor);
  }

  // date-time-picker
  .MuiClock-root {
    .MuiButtonBase-root {
      span {
        color: #fff !important;
      }
    }
  }

  .MuiTabs-vertical {
    border-color: rgb(255 255 255 / 12%);
  }

  .MuiButton-root.Mui-disabled {
    color: rgba(255, 255, 255, 0.26);
    background-color: rgba(255, 255, 255, 0.12);
  }

  .MuiMenu-paper {
    color: var(--darkBodyTextColor);
  }

  .MuiSelect-icon {
    color: var(--darkBodyTextColor);
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: hsla(0, 0%, 100%, 60%);
  }

  .MuiAccordion-root {
    border: 1px solid var(--borderColor);
    background-color: var(--cardBg);
    color: var(--darkBodyTextColor);
  }
}
